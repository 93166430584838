<template>
<div id="detail-view" body-scroll-lock-ignore>
  <div class="images" @scroll="onScroll">
    <img v-for="image in currentProject.images" 
      v-bind:key="image" v-bind:src="image" alt="">
    <arrowButton class="scroll-button" v-if="this.endOfImages"/>
  </div>
  <!-- <div class="images">
<vue-glide
      :bullet="true"
      type="slider"
      :autoplay="3000"
      :breakpoints="breakpoints"
      :perView="1"
    >
  <vue-glide-slide v-for="image in currentProject.images" v-bind:key="image" v-bind:src="image" alt="">
   <vue-glide-slide v-for="image in currentProject.images" 
      v-bind:key="image">
     <img  v-bind:src="image" alt="">
      </vue-glide-slide>
    </vue-glide>
  </div> -->
<div class="info">
  <div class="about">
   <div v-if="currentProject">
    <h1>{{ currentProject.title }}</h1>
    <h4>{{ currentProject.subtitle }}</h4>
    <p>{{ currentProject.year }}</p>
     
    <h3>Description</h3>
    <p v-html="currentProject.shortDescription"></p>
    <h3>Partner</h3>
    <span v-for="partner in currentProject.partner" v-bind:key="partner">{{ partner }}, </span>
   </div>
  </div>
</div>
  </div>

</template>

<script>
import store from "@/store.js";
// import arrowButton from "@/components/ArrowButton.vue"
import { Glide, GlideSlide } from "vue-glide-js";
import "vue-glide-js/dist/vue-glide.css";

export default {
  name: "portfolio",
  components:{
    // arrowButton: arrowButton,
    [Glide.name]: Glide,
    [GlideSlide.name]: GlideSlide
    },
  props: {},
  methods: {
    onScroll ({ target: { scrollTop, clientHeight, scrollHeight }}) {
      this.endofImages = false;
      if (scrollTop + clientHeight >= scrollHeight) {
        // alert("End Reached")
        this.endofImages = true;
        console.log(this.endofImages);
      }
    }
  },
  data() {
    return {
      currentProject: undefined,
      endofImages: undefined,
            breakpoints: {
        600: {
          // perView: 1.2
        },
        900: {
          // perView: 1.2
        }
      }
    };
  },
  created() {


    const foundProject = store.content.portfolio.find((projObj) => {
      return projObj.title.toLowerCase().replace(/ /g,"_") === this.$route.params.projectName;
    });



    this.currentProject = foundProject;
  },

};
</script>
<style scoped lang="scss">

#detail-view{
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-template-areas: "image image" "info info";
  //flex-direction: column;
  grid-area: content;
  overflow-y: scroll;
  // overflow-x: hidden;
  width: 100%;
  height: 100%;
  //align-items: stretch;
  //align-content: stretch;

  @media screen 
  and (min-device-width: 1000px){ 
    grid-template-areas: "image info" "image info";
}

}
img{
width: 100%;
 object-fit: cover;
 height: 100%;
}
.scroll-button{
  position: sticky;
  bottom: $padding;
  right: 0px;
}
.slider{
  border: 1px solid red;
}
.images{
  grid-area: image;
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  overflow-y: scroll;
  flex: 1;
  box-sizing: content-box;
      @media screen 
  and (min-device-width: 1000px){ 
    flex-direction: column;
}
}

.info{
  grid-area: info; 
   flex: 1; 
   padding: 1.5rem;
   overflow-y: scroll;
   border-top: $border;
   @media screen 
  and (min-device-width: 1000px){ 
    border-left: $border;
    padding-right: 100px;
}
}
.scroll-button{
  align-self: flex-end;
  transform: rotate(90deg);
  bottom: 0px;
}


</style>